.vinci {
  --green: #e20025;
  --green-dark: #de1628;
  --green-darken: #b0121f;
  --green-light: #fef5f6;
  --blue: #31acea;
  --blue-darken: #1aa3e8;
  --red: #ff4669;
  --purple: #c53ed1;
  --purple-darken: #ba2fc6;
  --gray-dark: #004489;
  --gray-dark-lighten: #193869;
  --gray: #61687f;
  --gray-darken: #565c71;
  --gray-light: #B4ABA1;
  --gray-lighter: #d7dee4;
  --alt-white-dark: #eff3f4;
  --alt-white: #f3f7f9;
  --black: #000000;
  --white: #ffffff;
  --white-transparent: rgba(255, 255, 255, .75);
  --arrow-up: #ff4669;
  --arrow-down: #00c793;
  --arrow-right: #31acea;
}

.login {
  .card {
    img {
      margin-top: 0px;
      height: 100px;
    }
  }
}

.sidebar {
  .logo {
    padding: 22px 55px 12px;
  }
}

