.table {
  margin-bottom: 30px;
  color: $gray-600;

  th, td {
    border: none;
    padding: 1.1em 1.3em;

    &.nbr {
      text-align: right;
    }

    &.status {
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }

  tr.active {
    background: rgba($primary, .3) !important;
    box-shadow: inset 0 0 0 1px $primary;
    border-right: 1px solid $primary;
  }

  thead th {
    text-transform: uppercase;
    color: $gray-500;
    border-bottom-width: 1px;
    vertical-align: top;

    &.reactable-header-sort-asc,
    &.reactable-header-sort-desc {
      color: $gray-600;
    }

    &.reactable-header-sortable {
      cursor: pointer;

      &:after {
        content: "";
        width: 1em;
        position: relative;
        top: 1px;
        font-size: $font-size-xs;
        margin-left: 1em;
        text-transform: none;
        display: inline-block;
        font-family: eleneo;
      }

      &.reactable-header-sort-asc:after {
        content: "t";
      }

      &.reactable-header-sort-desc:after {
        content: "w";
      }

      &.reactable-header-no-arrow:after {
        content: none;
      }
    }
  }

  tbody td {
    &:first-child {
      font-weight: bold;
    }

    .ecart-product {
      i {
        margin-left: 0.25em;
        font-size: $font-size-xs;
        position: relative;
        top: -1px;

        &.icon-bigarrow-down {
          color: $green;
        }

        &.icon-bigarrow-up {
          color: $red;
        }

        &.icon-bigarrow-right {
          color: $cyan;
        }
      }
    }
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background: $gray-100;
  }

  &.table-hover tbody tr:hover {
    background: $gray-300;
  }

  &.table-clickable tbody tr {
    .icon-eye {
      opacity: 0;
    }

    &:hover .icon-eye {
      opacity: 1;
    }
  }

  &.table-simple {
    tr {
      border-bottom: 1px solid $gray-300;

      td:first-child {
        padding-left: 0;
      }

      td:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}

.reactable-page-button,
.reactable-next-page,
.reactable-previous-page,
.reactable-current-page {
  font-size: 14px;
  margin-right: 5px;
}

.reactable-current-page {
  font-weight: bold;
}

.ReactTable-Parent {
  margin: 0px 25px 25px 25px;
}

.ReactTable {
  background: white;

  .rt-thead.-headerGroups {
    background: white !important;
  }
}

.table {
  margin-bottom: 30px;
  color: $gray-600;

  th, td {
    border: none;
    padding: 0.5em 1em;

    &.nbr {
      text-align: right;
    }

    &.status {
      text-align: center;
    }

    &:focus {
      outline: none;
    }
  }

  tr.active {
    background: rgba($primary, 0.3) !important;
    box-shadow: inset 0 0 0 1px $primary;
    border-right: 1px solid $primary;
  }

  thead th {
    text-transform: uppercase;
    color: $gray-500;
    border-bottom-width: 1px;
    vertical-align: top;

    &.reactable-header-sort-asc,
    &.reactable-header-sort-desc {
      color: $gray-600;
      box-shadow: inset 0 -3px 0 0 $gold-200 !important;
    }

    &.reactable-header-sortable {
      cursor: pointer;

      &:after {
        content: "";
        width: 1em;
        position: relative;
        top: 1px;
        font-size: $font-size-xs;
        margin-left: 1em;
        text-transform: none;
        display: inline-block;
        font-family: eleneo;
      }

      &.reactable-header-sort-asc:after {
        content: "t";
      }

      &.reactable-header-sort-desc:after {
        content: "w";
      }
    }
  }

  tbody td {
    .ecart-product {
      i {
        margin-left: 0.25em;
        font-size: $font-size-xs;
        position: relative;
        top: -1px;

        &.icon-bigarrow-down {
          color: $green;
        }

        &.icon-bigarrow-up {
          color: $red;
        }

        &.icon-bigarrow-right {
          color: $cyan;
        }
      }
    }
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background: $gray-100;
  }

  &.table-hover tbody tr:hover {
    background: $gray-300;
  }

  &.table-clickable tbody tr {
    cursor: pointer;

    .icon-eye {
      opacity: 0;
    }

    &:hover .icon-eye {
      opacity: 1;
    }
  }

  &.table-simple {
    tr {
      border-bottom: 1px solid $gray-300;

      td:first-child {
        padding-left: 0;
      }

      td:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
}

.ReactTable {
  .-sort-asc {
    box-shadow: inset 0 3px 0 0 $gold-200 !important;
  }

  .-sort-desc {
    box-shadow: inset 0 -3px 0 0 $gold-200 !important;
  }

  .-loading.-active > div {
    transform: translateY(-50%);
  }

  .rt-thead.-header {
    box-shadow: 0 2px 15px 0 hsla(200, 33, 50, 0.2);
  }

  .rt-expandable,
  .rt-expander::after {
    cursor: default !important;
    user-select: none;
  }

  &.-striped .rt-tr.-odd {
    background: $gray-100;
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: hsl(165, 55%, 94%);
  }
}

.alerts-table > .rt-table > .rt-tbody {
  overflow: hidden !important;
}
