.date-range {
  margin-top: -10px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  .date-range-wrapper {
    display: flex;
    perspective: 1000px;
    position: relative;

    .date-range-global {
      display: flex;
      transform: rotate3d(1, 0, 0, 0deg);
      transition: transform 0.5s ease;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }

    .date-range-load-curve {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;
      transform: rotate3d(-1, 0, 0, 180deg);
      transition: transform 0.5s ease;
      backface-visibility: hidden;
      transform-style: preserve-3d;

      .form-group {
        background-color: $gray-100;
      }
    }

    &.flipped {
      .date-range-global {
        transform: rotate3d(1, 0, 0, 180deg);
      }

      .date-range-load-curve {
        transform: rotate3d(1, 0, 0, 0deg);
      }
    }
  }

  .form-group {
    border: 1px solid $gray-300;
    padding: 10px 20px;
    display: block;
    position: relative;
    will-change: transform;
    backface-visibility: hidden;

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
      border-left: none;

      &:after {
        content: "a";
        font-family: eleneo;
        position: absolute;
        bottom: 1px;
        left: -40px;
        color: $gray-500;
        font-size: 1.6rem;
      }
    }

    &:not(:last-child) {
      border-right: none;
    }

    label {
      color: $gray-500;
      display: block;
      font-size: $font-size-xs;

      i {
        position: absolute;
        top: 30px;
        right: 20px;
        pointer-events: none;

        &.icon-event:before {
          display: inline !important;
        }

        &.icon-event {
          right: auto;
          left: 20px;
        }
      }
    }

    select, input {
      border: none;
      padding: 0;
      appearance: none;
      cursor: pointer;
      width: 150px;
      margin: 0;
      border-radius: 0;
      background: none;
    }

    input {
      padding-left: 18px !important;
    }

    select {
      padding: 10px 20px 10px 0;
      margin: (-10px) 0;
      width: 200px;
    }

    select.form-control:not([size]):not([multiple]),
    input.form-control {
      height: auto;
    }

    .rdtPicker {
      margin-top: 11px;
    }
  }
}
