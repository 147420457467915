.bills-list {
  .btn-link:hover, .btn-link:focus {
    text-decoration: none !important;
  }

  i {
    margin-right: 0.5em;
  }

  .icon-elec {
    color: $elec;
  }

  .icon-gaz {
    color: $gas;
  }

  table {
    table-layout: fixed;
  }

  td {
    overflow-wrap: break-word;
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    border: none !important;
  }

  thead {
    border-bottom: 1px solid rgb(215, 222, 228) !important;

    td {
      vertical-align: top;
    }
  }

  th {
    &:nth-child(2) {
      width: 110px;
    }

    &:nth-child(6) {
      width: 130px;
    }

    &:nth-child(8) {
      width: 80px;
    }

    &:nth-child(9) {
      width: 52px;
    }

    &:nth-child(10) {
      width: 80px;
    }
  }
}

.bills-list_filters {
  td {
    padding: 0 0 10px 10px;
  }
}

.bills-list_input-filter {
  height: 38px !important;
  font-size: 14px !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;

  &::placeholder {
    color: hsl(0, 0%, 50%);
  }
}

.bill-overlay {
  background: $gray-800;
  opacity: 0.9;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

.bill-nav {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 15000;

  a {
    color: $white;
    background: $gray-600;
    border-radius: 50%;
    font-size: 1.5rem;
    width: 3em;
    height: 3em;
    display: block;
    text-align: center;
    line-height: 2.8;
    position: absolute;
    text-decoration: none;
    transition: background 0.3s ease;

    &:hover {
      background: $gray-600;
    }

    &:first-child {
      left: 1.5em;

      i {
        left: -0.1em;
      }
    }

    &:last-child {
      right: 1.5em;

      i {
        right: -0.1em;
      }
    }

    i {
      position: relative;
    }
  }
}

.bill {
  position: fixed;
  top: 76px;
  max-height: calc(100vh - 76px);
  left: 50%;
  z-index: 20000;
  transform: translate(-50%, 0);
  overflow-y: auto;
  padding: 35px;
  background: $white;
  width: 85%;
  max-width: 1200px;

  .btn-close {
    position: absolute;
    right: 5px;
    top: 10px;
    background-color: #fff;
    border: none;
    box-shadow: none;
  }

  h2 {
    font-size: 2.25rem;
    color: $gray-600;
    font-weight: lighter;
    margin: 0 0 20px;
  }

  h4 {
    color: $gray-500;
    text-transform: uppercase;
    font-size: $font-size-base;
  }

  .bill-header {
    h3 {
      font-size: 3rem;
      margin-bottom: 20px;
      color: $gray-800;
    }

    img {
      width: 160px;
      margin-bottom: 20px;
    }
  }

  .bill-top {
    border: 1px solid $gray-300;
    margin: 0 0 40px;

    .bill-infos {
      padding: 15px 35px 15px 20px;
      font-size: $font-size-base;

      .form-control {
        display: inline-block;
        width: auto;
      }
    }

    .bill-total {
      background: $blue;
      color: $white;
      text-align: center;
      font-size: $font-size-lg;
      font-weight: lighter;

      > p {
        margin: 0;
        width: 100%;

        .price {
          font-size: 1.25em;
          font-weight: normal;

          small {
            font-weight: lighter;
            font-size: 0.65em;
            margin-left: 6px;
          }
        }
      }
    }
  }

  .addresses {
    margin-bottom: 40px;

    .form-group {
      margin: 0;

      .form-control {
        border: none;
        border-radius: 0;
        padding: 0;
        color: $gray-800;

        &::placeholder {
          color: $gray-300;
          font-weight: 100;
        }

        &.postal-code {
          width: 3.5em;
        }
      }
    }
  }

  .table {
    margin-bottom: 40px;

    thead th {
      border: none;
    }

    tbody {
      border: none;

      td {
        padding-bottom: 0;
        vertical-align: middle;

        &:first-child {
          font-weight: normal;
        }

        &[rowspan] {
          vertical-align: middle;
          padding-left: 0;
          line-height: 1;
          padding-bottom: 1.1em;

          i {
            font-size: 2.5rem;
            color: $blue;
            padding: 0 0.65em;
            vertical-align: middle;
            display: inline-block;

            + p {
              display: inline-block;
              vertical-align: middle;
              margin: 0;

              span {
                margin-bottom: 6px;
                display: inline-block;

                &.price {
                  margin: 0;
                  font-size: $font-size-lg;

                  small {
                    margin-left: 6px;
                  }
                }
              }
            }
          }

          &:last-child {
            text-align: center;

            i {
              font-size: inherit;
            }
          }
        }
      }

      tr {
        &:last-child {
          td {
            padding-bottom: 1.1em;
          }
        }

        &.row-0 {
          background: $gray-100;
        }
      }
    }
  }

  .bill-more {
    margin-bottom: 20px;

    .input-group {
      margin-bottom: 20px;

      .form-control {
        padding: 0.75rem;

        input {
          border: none;
          width: 100%;
          line-height: 20px;
        }
      }

      .input-group-addon {
        background: $gray-100;
        color: $gray-500;
        font-size: $font-size-base;
        padding: 8px 12px;
        border: 1px solid rgba(0, 0, 0, 0.15) !important;
        border-left: none !important;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    .bill-docs {
      h4 {
        margin-bottom: 15px;
      }

      h4:not(:first-child) {
        margin-top: 35px;
      }

      input {
        display: none;
      }

      ul:not(:first-child) {
        margin-top: 15px;
      }

      ul {
        padding: 0;
        list-style: none;
        border-bottom: 1px solid $gray-300;
        color: $gray-600;
        font-size: $font-size-sm;

        li > i {
          margin-right: 0.5em;
          position: relative;
          top: -1px;
        }

        .btn {
          color: $gray-500;
          cursor: pointer;
          text-decoration: none;
          padding: 0.5rem;

          &:last-child {
            font-size: $font-size-sm;
          }
        }
      }
    }
  }

  .bill-bottom {
    .btn {
      box-shadow: none;
      cursor: pointer;
      text-decoration: none;

      &:first-child {
        position: relative;
        color: $gray-500;

        &:hover {
          color: $gray-600;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0.75em;
          bottom: 0.5em;
          right: 0;
          width: 1px;
          background: $gray-300;
        }
      }
    }
  }

  .rdtPicker {
    margin-left: 236px;
  }

  .calendar-ico:hover {
    cursor: pointer;
  }
}

.vinci {
  .bill-total {
    background: #e20025 !important;
  }
  .bill td[rowspan] i {
    color:  #e20025 !important;
  }
}
