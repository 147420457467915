.topNav {
  padding: 0 20px;
  z-index: 500;
  position: fixed;
  top: 0;
  right: 0;
  left: 250px;
  background-color: #98D2DD !important;

  .nav-link {
    font-size: 1.2rem;
    padding: 0 20px !important;
    font-weight: normal;
    color: #FFFFFFBB;

    &:hover {
      color: $white !important;
    }

    span {
      display: block;
      padding: 26px 0 !important;
      position: relative;
    }

    &.active {
      color: $white !important;

      .active-nav {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 7px;
        background: $gold-700;
      }
    }
  }

  .nav-item {
    position: relative;
  }

  .btn-link {
    color: $white;
    font-size: $font-size-lg;
    text-decoration: none;
    cursor: pointer;

    .icon-smallarrow-down {
      font-size: $font-size-xs;
      margin-left: 6px;
    }
  }
}

.vinci {
  .topNav {
    background-color: #e20025 !important;
  }
  .active-nav {
    background: #b0121f !important;
  }
}
