.admin-marche {
  background: $white;

  .rdtPicker {
    width: auto;
  }

  .form-control {
    min-width: 150px;
  }

  .textarea {
    width: 50%;

    textarea {
      width: 100%;
      min-width: 200px;
    }
  }

  .new-info {
    background: lighten($blue, 37);
  }

  .btn {
    cursor: pointer;
  }
}
