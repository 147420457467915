.legend {
  display: flex;
  justify-content: center;
}

.graphContainer {
  min-height: 150px;
}

.legendItem {
  margin: 0 10px;
  font-size: 12px;
  &:before {
    content: "";
    vertical-align: middle;
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid var(--blue);
    margin: 0 10px;
  }
  &:nth-child(1) {
    &:before {
      background: var(--blue-alpha7);
    }
  }
  &:nth-child(2) {
    &:before {
      background:  var(--blue-alpha3);
    }
  }
}

.header {
  position: relative;
}

.pagination {
  user-select: none;
  margin: 0 150px;
  ul {
    justify-content: center !important;
    margin-bottom: 0;
  }
}

.btnCsv {
  float: right;
}
