.main-col {
  .main-header {
    position: sticky;
    top: 15px;
    margin-top: 77px;
    z-index: 400;

    &.not-sticky {
      position: static;
    }

    &.no-date-range {
      top: 10px;
    }

    + * {
      margin-top: 30px;
    }

    header {
      padding: 30px 40px 0;
      box-shadow: 0 -5px 5px 5px $gray-300;

      h1 {
        font-size: $font-size-h3;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .power-consumption-nav,
      .expenses-nav {
        font-size: 1.125rem;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 10px;

        .nav-link {
          color: $black;
          padding: 0 25px;

          > span {
            padding: 12px 0;
            display: inline-block;
            position: relative;
          }

          &.active {
            color: $gold;
            font-weight: bold;

            .active-underline {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 5px;
              background: $gold;
            }
          }
        }

        .nav-item:first-child {
          .nav-link {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.vinci {
  .expenses-nav, .power-consumption-nav {
    .nav-link.active {
      color: #e20025 !important;
    }
    .active-underline {
      background:  #e20025 !important;
    }
  }
}
