.market-kpis {
  > [class^="col"] {
    .card {
      border-radius: 0;
      border-right: none;
    }

    &:first-child {
      .card {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }
    }

    &:last-child {
      .card {
        border-right: 1px solid $gray-300;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }
    }
  }
}

.market-products {
  max-height: 409px;
  overflow-y: auto;
  margin-bottom: 30px;

  thead tr th {
    position: sticky;
    top: 0;
    background: $white;
    z-index: 1;
  }

  tbody tr {
    cursor: pointer;

    &.hidden-product {
      background: $gray-200;
      color: $gray-500;

      &:nth-of-type(odd) {
        background: $gray-200;
      }
    }
  }

  .only-on-over {
    display: none;
  }

  tr:hover .only-on-over {
    display: block !important;
  }
}

.col-header {
  min-height: 30px !important;
}

.price-monitoring {
  .input-datepicker {
    margin: 0;
  }

  .range-input-datepicker {
    z-index: 9;
    position: absolute;
    top: 5px;
    right: 52px;
    font-size: 12px;
  }

  .highcharts-input-group {
    display: none;
  }
}

.transaction-view {
  font-size: 12px;
  margin-top: 20px;

  .custom-control-label:after, .custom-control-label:before {
    top: 0.08rem;
  }

  .transaction-table,
  .transaction-graph {
    float: left;
    width: 100%;
    overflow-x: auto;
  }

  .transaction-table::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
  }

  .transaction-table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }

  .reactable-pagination {
    a {
      display: inline-block;
      padding: 0 16px;

      &.reactable-current-page {
        font-weight: 700;
      }
    }
  }

  .transaction-note {
    text-align: right;
    font-style: italic;
  }
}

.quotation-popover {
  .popover-inner {
    width: 700px;
  }

  .quotation-selection {
    input[type="radio"], input[type="checkbox"] {
      display: block;
      margin: 4px auto;
    }
  }

  .reactable-pagination {
    a {
      display: inline-block;
      padding: 0 16px;

      &.reactable-current-page {
        font-weight: 700;
      }
    }
  }
}

.input-datepicker {
  .btn {
    color: var(--gray-900) !important;
  }
}
