.filter-section {
  margin-bottom: 8px;

  .filter-title {
    font-size: 18px;
    font-weight: bold;
    color: $gray-900;
    margin-bottom: 8px;
  }

  label {
    color: $gray-600;
  }
}

.Select.Select--multi {
  .Select-menu-outer {
    border: solid 1px #d7dee4;
  }

  .Select-control {
    color: $gray-600;
    background-color: white;
    border: solid 1px #d7dee4;
    border-radius: 3px;

    .Select-multi-value-wrapper {
      .Select-placeholder {
        color: $gray-600;
      }

      .Select-value {
        padding-left: 0;
        line-height: 20px;
        background-color: $gray-100;
        border: solid 1px #d7dee4;

        .Select-value-icon {
          border-right: solid 1px #d7dee4;
          color: $gray-600;
          font-weight: bold;
        }

        .Select-value-label {
          color: $gray-600 !important;
        }
      }
    }
  }
}

.select-inline {
  display: inline-block !important;
  width: auto;
  min-width: 200px;
  vertical-align: middle;
}

.select-square,
.select-square > .Select-control {
  border-radius: 0 !important;
  border: none !important;
}

.Select.Select--single.Select-white {
  .Select-menu-outer {
    border: solid 1px #d7dee4;
  }

  .Select-control {
    color: $gray-600;
    background-color: white;
    border: solid 1px #d7dee4;
    border-radius: 3px;

    .Select-multi-value-wrapper {
      .Select-placeholder {
        color: $gray-600;
        font-weight: 300;
      }

      .Select-value {
        padding-top: 2px;

        .Select-value-label {
          color: $gray-600 !important;
        }
      }
    }
  }
}

.Select.table-col-filter {
  width: 18px;
  height: 18px;
  line-height: 18px;
  display: inline-block;
  border-radius: 0;
  vertical-align: baseline;
  position: relative;
  top: 3px;
  margin-right: 5px;
  text-transform: none;
  font-weight: normal;

  .Select-control {
    background: $gray-300;
    color: $gray-500;
    border-radius: 100%;
    border: none;
    height: auto;

    .Select-multi-value-wrapper {
      display: none;
    }

    .Select-clear-zone {
      display: none;
    }

    .Select-arrow-zone {
      padding: 0;

      &:after {
        content: '8';
      }
    }

    .Select-value {
      display: none;
    }
  }

  .Select-menu-outer {
    width: 260px;
    margin-left: -200px;
    background: $white;
    border: 1px solid $gray-300;
    padding: 60px 15px 10px;
    z-index: 1;
    margin-top: 10px;

    .Select-option {
      background: none;
      padding: 5px 0;

      &:nth-child(2n) {
        background: none;
      }

      &:last-child {
        border-radius: 0;
      }

      &.is-selected {
        background: $primary;
        font-weight: 300;

        &:before {
          content: 'T';
          font-family: 'eleneo';
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 10px;
          text-transform: none;
          padding: 0 10px;
          line-height: 1;
        }
      }
    }
  }

  &.is-open {
    .Select-arrow-zone {
      color: $primary;
    }

    .Select-multi-value-wrapper {
      display: block;
      position: absolute;
      width: 230px;
      margin-left: -185px;
      top: 45px;
      z-index: 10;
      border: 1px solid $gray-300;
      border-radius: 3px;

      &:after {
        content: '9';
        font-family: 'eleneo';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        text-transform: none;
        padding: 0 10px;
        line-height: 1;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
        color: $gray-600;
        background: $white;
      }
    }
  }
}

.Select.Select--single.active > .Select-control {
  background: $primary !important;
  color: $white !important;

  .Select-multi-value-wrapper {
    .Select-value {
      color: $white !important;

      .Select-value-label {
        color: $white !important;
      }
    }
  }
}

.popover-body {
  .Select-input {
    height: 40px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 40px;
  }
}

.form-check-list {
  padding-left: 0;
  list-style: none;

  ul {
    margin-top: 3px;
    list-style: none;
    padding-left: 20px;

    li {
      margin-top: 3px;
    }
  }
}

.form-check-label {
  padding: 0;
  cursor: pointer;

  input {
    display: none;
  }

  &:before {
    content: 'i';
    font-family: eleneo;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    line-height: 1;
    color: $gray-500;
    margin-right: 0.6em;
  }

  &.disabled {
    &:before {
      content: 'y';
      color: $gray-500;
    }
  }

  &.checked {
    &:before {
      content: 'g';
      color: $cyan;
    }

    &.disabled {
      &:before {
        color: $gray-500;
      }
    }
  }
}

.form-check {
  .form-control-label {
    &:before {
      content: '';
      width: 14px;
      height: 14px;
      display: inline-block;
      margin-right: 5px;
      border: 1px solid $gray-500;
      border-radius: 100%;
    }

    &.disabled {
      &:before {
        background: $gray-300;
      }
    }

    &.checked {
      &:before {
        background: $blue;
        border-color: $blue;
      }

      &.disabled {
        &:before {
          background: $gray-500;
        }
      }
    }

    input {
      display: none;
    }
  }
}

.form-inline {
  .form-check + .form-check {
    margin-left: 10px;
  }
}

.title.inline {
  display: inline-block;
  font-size: 18px;
  font-weight: bold;
  color: #61747f;
  margin-right: 20px;
}

.form-control-label {
  cursor: pointer;
}

.choice-group {
  margin-left: 0;
  margin-right: 0;
  display: inline-block;

  &.full-width {
    width: 100%;
    display: flex;

    .form-check {
      flex-grow: 1;
    }
  }

  .form-check {
    margin: 0;
    text-align: center;
    display: inline-block;
    border-top: solid 1px #d7dee4;
    border-bottom: solid 1px #d7dee4;
    border-right: solid 1px #d7dee4;

    &:first-child {
      border-left: solid 1px #d7dee4;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:hover {
      background-color: $gray-300;
    }

    .form-check-label {
      color: $gray-800;
      font-size: 16px;
      padding: 8px;
      width: 100%;

      &:before {
        display: none;
      }
    }
  }

  &.last-apart {
    .form-check {
      &:last-child {
        margin-left: 5px;
        border-left: solid 1px #d7dee4;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      &.last-group-item {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

.input-box {
  position: relative;
  border: 1px solid $gray-300;
  border-radius: 3px;
  padding-top: 6px;
  margin: 0;

  .form-control-label {
    position: absolute;
    top: 2px;
    left: 12px;
    font-size: 10px;
    z-index: 1;
  }

  .form-control {
    border: none;
    padding-bottom: 4px;
  }
}


.form-control {
  border-color: $gray-300;
}

.form-check-label {
  padding: 0;

  .form-inline & {
    margin-right: 30px;
  }

  &:before {
    content: "i";
    font-family: eleneo;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    line-height: 1;
    color: $gray-500;
    margin-right: 0.3em;
  }

  &.disabled {
    &:before {
      content: "y";
      color: $gray-500;
    }
  }

  &.checked {
    &:before {
      content: "g";
      color: $cyan;
    }

    &.disabled {
      &:before {
        color: $gray-500;
      }
    }
  }

  &.indeterminate {
    &:before {
      content: "h";
      color: $cyan;
    }

    &.disabled {
      &:before {
        color: $gray-500;
      }
    }
  }
}

.form-inline label + * {
  margin: 0 30px 0 1em;
}

.form-graph {
  margin-bottom: 20px;
}
