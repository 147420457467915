@import "~react-table/react-table.css";
@import "~react-table-hoc-fixed-columns/lib/styles.css";
@import "~react-dropdown-tree-select/dist/styles.css";
@import "~@reach/listbox/styles.css";
@import "~flag-icon-css/css/flag-icon.css";

@import "variables.module";
@import "~bootstrap/scss/bootstrap";
@import "bootstrap-migration";

@import "react-datetime";
@import "icons";
@import "buttons";
@import "forms";
@import "tables";
@import "popover";
@import "sidebar";
@import "panels";
@import "topNav";
@import "header";
@import "dateRange";
@import "kpi";
@import "market";
@import "login";
@import "bills";
@import "accordion";
@import "notifs";
@import "adminmarche";
@import "contracts";
@import "version";
@import "react-select";
@import "tabs";

@import "themes/vinci";
@import "themes/stef";

@font-face {
  font-family: 'Merlo';
  src: url('../fonts/merlo/merlo-neue-round-light-webfont.woff2') format('woff2'),
       url('../fonts/merlo/merlo-neue-round-light-webfont.woff') format('woff');
}

// Scrollbars
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba($gray-500, 1);
}

::-webkit-scrollbar-track {
  background: rgba($gray-300, 1);
}

:root {
  --gold: #98d2dd;
  --gold-400: #{$gold-400};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --gray-900: #{$gray-900};
  --elec: #{$elec};
  --gas: #{$gas};
}

html {
  font-size: 14px;
}

body {
  color: $gray-700;
  font-size: $font-size-sm;

  &.no-scroll {
    overflow: hidden;
  }
}

button, input, optgroup, select, textarea {
  font-family: $font-family-sans-serif;
}

[class^="icon-"]:before,
[class*="icon-"]:before {
  vertical-align: middle;
}

.main-col {
  background: $gray-200;
  width: calc(100% - 250px);
  min-height: 100vh;
  flex-basis: auto;

  header,
  .navbar,
  section {
    background: $white;
  }

  section {
    border-radius: 3px;
    margin: 0 10px 50px;
    padding: 30px;
    box-shadow: 2px 2px 5px $gray-300;

    .section-header {
      margin: 0 -30px 30px;
      padding: 0 15px 25px;
      border-bottom: 1px solid $gray-300;
    }

    h5 {
      font-size: $font-size-lg;
      margin-bottom: 20px;
    }
  }
}

.expenses-chart {
  margin: (-20px) 0 20px;
}

.pie-title {
  width: 150px;
  position: absolute;
  left: 92%;
  margin-left: -108px;
  text-align: center;
  z-index: 1;
}

.highcharts-root {
  font-family: $font-family-sans-serif !important;
}

.highcharts-stack-labels tspan {
  fill: #555 !important;
  font-size: 11px;
}

.expense-graph-legend {
  display: flex;
  justify-content: center;

  button {
    cursor: pointer;
    margin: 10px;
    opacity: 0.3;

    &.btn-selected {
      opacity: 1;
    }
  }
}

$energyColor:#009fe3;
$gridColor:#5A57A0;
$taxesColor: #515BB3;
$othersColor:#B9525A;

.btn-Energie {
  @include button-variant(#fff, $energyColor, $energyColor);
}

.btn-Transport {
  @include button-variant(#fff, $gridColor, $gridColor);
}

.btn-Taxes {
  @include button-variant(#fff, $taxesColor, $taxesColor);
}

.btn-Autres {
  @include button-variant(#fff, $othersColor, $othersColor);
}

button.reset {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  i {
    color: #61687f;
    font-size: 12px;
  }
}

path.highcharts-legend-nav-active {
  fill: #00c793;
}

path.highcharts-legend-nav-inactive {
  fill: $gray-600;
}

.highcharts-credits {
  display: none;
}

.highcharts-legend-item {
  .highcharts-grid-line {
    display: none !important;
  }
}


// Migration charte
label.btn:active,
.btn-link:active {
  box-shadow: none !important;
}
.form-control {
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}
