.kpi {
  margin-bottom: 30px !important;

  &.empty {
    background: $gray-100;
  }

  .card-body {
    padding: 1em;

    .card-subtitle {
      margin: 0 0 0.25em;
      color: $gray-600;
      font-size: $font-size-sm;
    }

    .card-title {
      font-size: $font-size-h4;
      font-weight: bold;
      float: left;

      small {
        color: $gray-600;
      }
    }

    .secondary {
      .card-text {
        border-bottom: 1px solid $gray-300;
        margin: 0;
        padding: 0.5em 0;
        color: $gray-600;
        overflow: hidden;
        width: 100%;

        &:last-child {
          border: none;
        }

        i {
          margin-left: 0.25em;
          font-size: $font-size-xs;
          position: relative;
          top: -2px;

          &.icon-bigarrow-up {
            color: $red;
          }

          &.icon-bigarrow-right {
            color: $cyan;
          }

          &.icon-bigarrow-down {
            color: $green;
          }
        }

        .actual-value {
          padding: 0 10px;
        }

        .percent-value {
          display: inline-block;
        }
      }
    }
  }

  .principal-additional {
    float: right;
    font-size: 0.9em;
    line-height: 13px;

    .principal-additional-line {
      width: 100%;
      margin: 2px 0px;
    }

    .principal-additional-fac {
      display: inline-block;
      float: right;
    }

    .principal-additional-fac-value {
      font-weight: 700;
      font-size: 1.05em;
      display: inline-block;
      padding: 0 4px;
    }
  }

  @media only screen and (max-width: 1730px) {
    .principal-additional {
      position: unset;
      font-size: unset;
      line-height: unset;
      float: left;
      width: 100%;
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
  }
}

.all-amenities-kpis {
  align-self: center;

  .kpi {
    margin-bottom: 0;
  }
}
