// .btn-primary {
//   border-color: $green;
//   background-color: $green;

//   &:hover {
//     border-color: $green-dark;
//     background-color: $green-dark;
//   }

//   &:active, &.active {
//     border-color: $green-darken;
//     background-color: $green-darken;
//   }

//   &:focus {
//     box-shadow: none;
//   }
// }

// .btn-link {
//   color: $green;

//   &:hover {
//     color: $green-dark;
//   }

//   &:active, &:focus {
//     color: $green-darken;
//   }
// }

// .btn-secondary {
//   color: $gray-800;
//   background: none;
//   border-color: $gray-300;

//   &:not([disabled]):not(.disabled) {
//     &:hover,
//     &:active {
//       color: $gray-800;
//       background: none;
//       box-shadow: none;
//     }
//     &:focus {
//       background: none;
//       box-shadow: none;
//     }
//   }
//   &.disabled {
//     background: none;
//     box-shadow: none;
//     cursor: not-allowed;
//   }
// }

.btn-table {
  padding: 0;
  font-size: inherit;
  border-radius: 0;
  border: none;
  background: none;
  color: $gray-300;

  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: $gray-600;
    background: none;
    box-shadow: none;
  }

  &:hover {
    color: $primary !important;
  }
}
