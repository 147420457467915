$selectColor: rgb(81, 91, 179);
$focusColor: rgba(81, 91, 179, .3);

.rs {
  .rs__indicator-separator {
    display: none;
  }

  .rs__control--is-focused {
    border-color: transparent;
    box-shadow: 0 0 0 1px $selectColor;
    &:hover {
      border-color: transparent;
    }
  }

  .rs__option {
    &:active {
      background: $selectColor;
      color: #fff;
    }
  }

  .rs__option--is-focused {
    background: $focusColor;
  }

  .rs__option--is-selected {
    background: $selectColor;
  }
}

.date-range {
  .rs {
    height: 38px;
  }

  .rs__control {
    border: none;
    box-shadow: none;
  }

  .rs__value-container {
    padding: 0;
  }

  .rs__menu {
    border-radius: 2px;
    margin-top: 0;
  }
}

.rs--small {
  .rs__control {
    border-radius: 3px;
  }

  .rs__menu {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-top: -2px;
  }
}

.rs__group-heading {
  background-color: rgb(215, 222, 228);
  color: #61687f !important;
  font-weight: bold !important;
  padding: 5px 0;
}

.rs__value-container--is-multi {
  &.rs__value-container--has-value {
    padding: 4px 4px;
  }
}

.rs__multi-value__label {
  max-width: 80px;
}
