.simple-nav-tab {
  border-bottom: 1px solid #ddd;

  .nav-item {
    margin-bottom: 0 !important;
  }

  .nav-link {
    user-select: none;
    cursor: pointer;
    font-size: 14px;
    color: #61687f !important;
    border-radius: 0 !important;
    border: 1px solid #ddd !important;
    border-bottom: none;
    background: #fff !important;
    padding: .8rem;
    margin-left: -1px;
    margin-bottom: -1px;
    position: relative;

    &:hover::after,
    &.active::after {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      position: absolute;
      bottom: -1px;
      left: 0;
    }

    &:hover::after {
      background: #00c79350;
    }

    &.active::after {
      background: #00c793;
    }

    i {
      padding-right: 8px;
    }
  }
}
