.legend {
  display: flex;
  justify-content: center;
}

.legendItem {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
}

.chartContainer {
  min-height: 400px;
  position: relative;
}

.loaderContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .7);
  top: 0;
}
