$font-family-sans-serif: Muli, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;

$gold: rgb(152, 210, 221) !default;
$blue: #21215A !default;
$cyan: #515BB3 !default;
$red: #B9525A !default;
$orange: #ED7250 !default;
$yellow: #F3984B !default;
$purple: #645892 !default;
$green: #007b5a !default;

$gold-200: lighten($gold, 30%);
$gold-400: lighten($gold, 10%);
$gold-700: darken($gold, 10%);
$blue-600: darken($blue, 4%);

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #000000 !default;

$primary: $cyan !default;
$brand-primary: $blue;
$secondary: $gold !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-300 !default;
$dark: $gray-800 !default;

$border-radius: .123rem !default;
$border-radius-lg: .15rem !default;
$border-radius-sm: .1rem !default;

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-pointer-cursor-for-buttons: true !default;
$enable-rfs: false !default;
$enable-validation-icons: true !default;
$enable-deprecation-messages: true !default;

$sidebar-width: 250px;
$seachPanel-width: 480px;

$elec: #31acea;
$gas: #c53ed1;

:export {
  fontFamily: $font-family-sans-serif;

  blue: $blue;
  red: $red;
  orange: $orange;
  yellow: $yellow;
  cyan: $cyan;
  purple: $purple;
  green: $green;

  primary: $primary;
  secondary: $secondary;
  success: $success;
  info: $info;
  warning: $warning;
  danger: $danger;
  light: $light;
  dark: $dark;
}


:root {
//  --gold: #926A14;
//  --gold-dark: #65490e;
//  --top: #00ba8a;
//  --top-light: #b2eede;
//  --flop: rgb(185, 82, 90);
//  --flop-light: rgba(185, 82, 90, 0.36);
  --blue-alpha3: rgba(33, 33, 90, 0.3);
  --blue-alpha7: rgba(33, 33, 90, 0.7);
  --blue: #31acea;
}
