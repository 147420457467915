.notifications-wrapper {
  position: relative;
  z-index: 200000;

  .notifications-bl {
    bottom: 20px !important;
    left: 20px !important;
  }

  .notification {
    border-radius: 1000px !important;
    background-color: $white !important;
    box-shadow: 0 0 15px 0 rgba(#2e313d, 0.2) !important;
    border: none !important;
    padding: 25px 40px !important;
    height: auto !important;

    h4 {
      margin: 0 !important;
      font-weight: normal !important;
      color: $green !important;
    }

    &.notification-error {
      h4 {
        color: $red !important;
      }
    }

    .notification-dismiss {
      display: none;
    }
  }
}
